import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import useApplicationState from "state/ApplicationState";

const QuestionSelectionPage = () => {
  return (
    <div className="flex items-center justify-center w-full h-auto min-h-full">
      <div className="relative flex items-center justify-center h-auto p-2 m-5 bg-mainBg border-shadow xl:h-3/4">
        <NavLink
          to={"/home"}
          className="absolute flex items-center justify-center py-1 pl-2 border-2 border-black rounded-md cursor-pointer bg-itemBg top-4 left-6"
        >
          <span class="material-symbols-outlined">arrow_back_ios</span>
        </NavLink>

        <div className="flex flex-col items-center justify-center w-full gap-2 mt-16 mb-8 lg:w-3/4 h-3/4">
          <div className="p-2 border-2 border-black rounded-md bg-itemBg border-shadow top-4">
            <p className="text-xl">
              What type of quiz category would you like to submit?
            </p>
          </div>
          <SelectionButton
            text="Basic"
            description={
              "A question with four POSSIBLE options. The player has to guess the correct one."
            }
          />
          <SelectionButton
            text="Calculation"
            description={
              "A question that has a numerical answer. The player whose estimated number is closer to the correct number wins."
            }
          />
          <SelectionButton
            text="Ordering"
            description={
              "A title and maximum 4-6 statement. The player must arrange the statements in the correct order."
            }
          />
          <SelectionButton
            text="Linking"
            description={
              "One title with several statements. The player needs to properly connect the statements."
            }
          />
          <SelectionButton
            text="Question Bombardment"
            description={
              "Five easy-to-interpret, yes-or-no questions with quick response times."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionSelectionPage;

const SelectionButton = (props) => {
  const { text, description } = props;
  const state = useApplicationState();
  const navigate = useNavigate();

  return (
    <div
      className="flex items-center justify-center w-3/4 h-1/2"
      onClick={() => {
        if (text === "Coming soon...") return;
        const data = state.questionDataCopy();
        data.type = text;
        state.setQuestionData(data);
        navigate("/question-category");
      }}
    >
      <div className="flex flex-col w-full p-2 px-4 transition-all duration-200 border-2 border-black cursor-pointer bg-itemBg border-shadow hover:bg-opacity-90">
        <p className="text-xl text-left text-black">{text}</p>
        <p className="text-lg text-black">{description}</p>
      </div>
    </div>
  );
};
