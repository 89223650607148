import { create } from "zustand";
import axios from "axios";
import { CONFIG } from "../config/ApplicationConfig.js";

const useApplicationState = create((set) => ({
  user: null,
  setUser: (user) => set(() => ({ user: user })),
  fetchUser: async () => {
    try {
      const response = await axios.get(`${CONFIG.API_URL}/discord/oauth/user`, {
        withCredentials: true,
      });
      set(() => ({ user: response.data.user }));
      return response.data.user;
    } catch (err) {
      set(() => ({ user: null }));
      return null;
    }
  },
  questionData: {
    type: null,
    category: null,
    subCategory: null,
    question: null,
    answers: {},
  },
  setQuestionData: (questionData) =>
    set(() => ({ questionData: questionData })),
  questionDataCopy: () => {
    return { ...useApplicationState.getState().questionData };
  },
  resetQuestionData: () => {
    set(() => ({
      questionData: {
        type: null,
        category: null,
        subCategory: null,
        question: null,
        answers: {},
      },
    }));
  },
}));

export default useApplicationState;
